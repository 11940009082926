import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
function SEO({
  description,
  lang,
  meta,
  template,
  image: metaImage,
  title,
  pathname,
  schemaMarkup,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
            logo {
              src
              width
              height
            }
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null

  const imageTemp = metaImage && metaImage.src ? metaImage.src : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const logo = site.siteMetadata.logo
    ? `${site.siteMetadata.siteUrl}${site.siteMetadata.logo.src}`
    : null

  const schema = schemaMarkup
    ? {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebSite",
            "@id": site?.siteMetadata?.siteUrl + "/#website",
            url: site?.siteMetadata?.siteUrl,
            name: site?.siteMetadata?.title,
          },
          {
            "@type": ["Person", "Organization"],
            "@id": site?.siteMetadata?.siteUrl,
            name: site?.siteMetadata?.title,
            url: logo,
            image: {
              "@type": "ImageObject",
              "@id": logo,
              inLanguage: "en-US",
              url: logo,
              width: site.siteMetadata.logo.width,
              height: site.siteMetadata.logo.height,
              caption: site?.siteMetadata?.title,
            },
            logo: {
              "@id": site?.siteMetadata?.siteUrl,
            },
          },
          schemaMarkup,
        ],
      }
    : {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebSite",
            "@id": site?.siteMetadata?.siteUrl + "/#website",
            url: site?.siteMetadata?.siteUrl,
            name: site?.siteMetadata?.title,
          },
          {
            "@type": ["Person", "Organization"],
            "@id": site?.siteMetadata?.siteUrl,
            name: site?.siteMetadata?.title,
            url: logo,
            image: {
              "@type": "ImageObject",
              "@id": logo,
              inLanguage: "en-US",
              url: logo,
              width: site.siteMetadata.logo.width,
              height: site.siteMetadata.logo.height,
              caption: site?.siteMetadata?.title,
            },
            logo: {
              "@id": logo,
            },
          },
        ],
      }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: `og:url`,
          content: site?.siteMetadata?.siteUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: template ? imageTemp : image,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  property: "og:image",
                  content: logo,
                },
                {
                  property: "og:image:width",
                  content: site.siteMetadata.logo.width,
                },
                {
                  property: "og:image:height",
                  content: site.siteMetadata.logo.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
        )
        .concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}
export default SEO
